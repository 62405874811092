import useAppDispatch from "@/hooks/useAppDispatch";
import { useEffect } from "react";
import type { GetServerSideProps, NextPage } from "next";
import Head from "next/head";
import { useRouter } from "next/router";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Link from "@/components/Link";
import { Container, SignUpOrLoginFormContainer } from "@/features/auth/components/styled";
import { getInvite, getUser, logoutThunk } from "@/features/auth/auth-queries";
import useMessage from "@/hooks/useMessage";
import { wrapper } from "@/config/store";
import getPartnerTheme from "@/features/layout/getPartnerTheme";
import useLogin from "@/features/auth/useLogin";
import LoginForm from "@/components/LoginForm";
import { TopBar } from "@/features/onboarding/components";

const LogIn: NextPage<{
  role_id?: string;
  email?: string;
  cell_phone?: string;
  account_name?: string;
  partnership_name?: string;
  isMobileApp?: boolean;
  partnershipId?: string;
  isInviteExpire: boolean;
}> = ({ role_id, email, cell_phone, account_name, partnership_name, isMobileApp, partnershipId, isInviteExpire }) => {
  const router = useRouter();
  const isLogout = router?.query?.logout === "true";
  const [message, setMessage] = useMessage();

  const {
    showOneTimeCodeInput,
    showPasswordInput,
    showTwoFaInput,
    setShowOneTimeCodeInput,
    setShowPasswordInput,
    setShowTwoFaInput,
    resetForm,
    initiateLogin,
    loginFormik,
    sendOneTimeCode,
  } = useLogin({
    setMessage,
    roleId: role_id,
    initialValues: {
      email,
      cell_phone,
    },
  });

  useEffect(() => {
    if (isLogout) {
      dispatch(logoutThunk());
    }
  }, [isLogout]);

  const dispatch = useAppDispatch();

  const loginUrl = "/login" + (partnershipId ? `?partnership_id=${partnershipId}` : "");

  return (
    <>
      <Head>
        <title>Log In - Finli</title>
      </Head>
      <TopBar
        action={
          isMobileApp ? null : (
            <Link
              href={"/signup" + (partnershipId ? `?partnership_id=${partnershipId}` : "")}
              sx={{
                color: "#EAFCD2",
                fontSize: "0.9rem",
                marginLeft: 3,
                "&:hover": {
                  textDecoration: "underline",
                },
              }}
            >
              Sign up for a business account
            </Link>
          )
        }
      />
      <Container
        sx={{
          backgroundColor: "grey.100",
          flexDirection: { lg: "column" },
        }}
      >
        <SignUpOrLoginFormContainer>
          <Typography
            noWrap
            display="flex"
            justifyContent="center"
            textAlign="center"
            overflow="visible"
            fontWeight={600}
            fontFamily="montserrat"
            sx={{ fontSize: "1.5rem", marginBottom: "2rem" }}
          >
            {role_id ? (isInviteExpire ? "Oops!" : "Welcome!") : "Welcome Back!"}
          </Typography>
          {role_id &&
            (isInviteExpire ? (
              <>
                <Typography variant="body1" sx={{ margin: "0.5rem 0 0" }}>
                  It seems like your invitation has expired.
                </Typography>
                <Typography variant="body2" sx={{ color: "grey.600", marginTop: "1rem" }}>
                  Already have an account?{" "}
                  <Link
                    onClick={() => {
                      router.push(loginUrl);
                    }}
                  >
                    Log in
                  </Link>
                </Typography>
              </>
            ) : (
              <>
                <Typography variant="body1" sx={{ margin: "0.5rem 0 0 0" }}>
                  You have been invited to join
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    fontSize: "1.25rem",
                    fontWeight: 500,
                    margin: "0 0 0.5rem 0",
                  }}
                >
                  {decodeURIComponent(account_name || partnership_name || "")}
                </Typography>
                <Typography variant="body1">Please login to accept your invitation</Typography>
                <Typography
                  variant="body1"
                  sx={{
                    fontSize: "1.25rem",
                    fontWeight: 500,
                    margin: "0 0 2rem 0",
                  }}
                >
                  {email ?? cell_phone}
                </Typography>
              </>
            ))}
          {(!role_id || !isInviteExpire) && (
            <LoginForm
              message={message}
              roleId={role_id}
              showOneTimeCodeInput={showOneTimeCodeInput}
              showPasswordInput={showPasswordInput}
              showTwoFaInput={showTwoFaInput}
              setShowOneTimeCodeInput={setShowOneTimeCodeInput}
              setShowPasswordInput={setShowPasswordInput}
              setShowTwoFaInput={setShowTwoFaInput}
              resetForm={resetForm}
              initiateLogin={initiateLogin}
              loginFormik={loginFormik}
              sendOneTimeCode={sendOneTimeCode}
              isMobileApp={isMobileApp}
              partnershipId={partnershipId}
            />
          )}
        </SignUpOrLoginFormContainer>
        <Box marginTop="2rem" textAlign="center">
          {role_id ? null : (
            <Typography variant="body2" marginBottom="1rem">
              Paying an invoice?{" "}
              <Link href="https://support.finli.com/s/article/paying-with-Finli" target="_blank">
                Learn more
              </Link>
            </Typography>
          )}
          <Typography variant="body2">
            By signing up, you agree to the&nbsp;
            <Link href="https://finli.com/terms-and-conditions" target="_blank">
              Terms of Use
            </Link>
            &nbsp;and&nbsp;
            <Link href="https://finli.com/privacy-policy" target="_blank">
              Privacy Policy
            </Link>
            .
          </Typography>
        </Box>
      </Container>
    </>
  );
};

export const getServerSideProps: GetServerSideProps = wrapper.getServerSideProps((store) => async (ctx) => {
  if (ctx?.query?.logout === "true") {
    return {
      props: {},
    };
  }

  try {
    let user = await getUser(ctx.req);

    if (user?.id) {
      return {
        redirect: {
          destination: "/user/settings",
          permanent: false,
        },
      };
    }
  } catch (err: any) {}

  const partnershipId = (ctx?.query?.partnership_id as string)?.split(",")[0];

  const partnerTheme = await getPartnerTheme(partnershipId, store, ctx);

  const role_id = ctx?.query?.member_invite_id ?? null;
  const account_name = ctx?.query?.account_name ? decodeURIComponent(ctx?.query?.account_name as string) : null;
  const partnership_name = ctx?.query?.partnership_name
    ? decodeURIComponent(ctx?.query?.partnership_name as string)
    : null;
  const email = ctx?.query?.email ? decodeURIComponent(ctx?.query?.email as string) : null;
  const cell_phone = ctx?.query?.cell_phone ? decodeURIComponent(ctx?.query?.cell_phone as string) : null;

  // @ts-ignore
  const isMobileApp = ctx?.query?.mobileapp?.toLowerCase() === "true";

  let isInviteExpire = false;

  try {
    if (ctx.query?.member_invite_id) {
      await getInvite(ctx.query?.member_invite_id as string, ctx.req);
    }
  } catch (e) {
    isInviteExpire = true;
    console.error(e);
  }

  return {
    props: {
      role_id,
      account_name,
      partnership_name,
      email,
      cell_phone,
      isMobileApp,
      partnershipId: partnershipId ?? null,
      ...(Object.keys(partnerTheme ?? {}).length > 0 ? { partnerTheme } : {}),
      isInviteExpire,
    },
  };
});

export default LogIn;
